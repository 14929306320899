import { useState } from "react";

import Task from "./Task";

import axios from "./axios_instance";

import "./App.css";
import { useEffect, useRef } from "react";

function App() {
  const x = useRef(null);

  const [tasks, setTasks] = useState([]);

  const [taskContent, setTaskContent] = useState("");

  const removeTask = (index) => {
    const newTasks = [...tasks];

    axios.delete(`tasks/${newTasks[index].id}.json`);

    newTasks.splice(index, 1);
    setTasks(newTasks);
  };

  const doneTask = (index) => {
    const newTasks = [...tasks];
    newTasks[index].isDone = !newTasks[index].isDone;

    axios.put(`/tasks/${newTasks[index].id}.json`, {
      content: newTasks[index].content,
      isDone: newTasks[index].isDone,
    });

    setTasks(newTasks);
  };

  const setTaskHandler = (e) => {
    setTaskContent(e.target.value);
  };

  const addTask = (e) => {
    e.preventDefault();

    if (taskContent !== "") {
      const newTasks = [...tasks];
      newTasks.push({
        isDone: false,
        content: taskContent,
      });

      axios.post("/tasks.json", {
        isDone: false,
        content: taskContent,
      });

      setTasks(newTasks);
      setTaskContent("");
    }
  };

  const listTasks = tasks.map((task, index) => {
    return (
      <Task
        key={index}
        isDone={task.isDone}
        content={task.content}
        removeTask={() => removeTask(index)}
        doneTask={() => doneTask(index)}
      />
    );
  });

  useEffect(() => {
    x.current.focus();

    axios.get("/tasks.json").then((response) => {
      const newTasks = [];

      for (let i in response.data) {
        newTasks.push({
          ...response.data[i],
          id: i,
        });
      }

      setTasks(newTasks);
    });
  }, []);

  return (
    <div className="App">
      <header>
        <span>TO-DO</span>
      </header>

      <div className="add">
        <form onSubmit={addTask}>
          <input
            ref={x}
            type="text"
            placeholder="Que souhaitez-vous ajouter ?"
            value={taskContent}
            onChange={setTaskHandler}
          />
          <button type="submit">Ajouter</button>
        </form>
      </div>

      {listTasks}
    </div>
  );
}

export default App;
